// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-films-js": () => import("./../../../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-movie-showtimes-js": () => import("./../../../src/pages/movie-showtimes.js" /* webpackChunkName: "component---src-pages-movie-showtimes-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-basic-content-js": () => import("./../../../src/templates/basic-content.js" /* webpackChunkName: "component---src-templates-basic-content-js" */),
  "component---src-templates-cineleisure-js": () => import("./../../../src/templates/cineleisure.js" /* webpackChunkName: "component---src-templates-cineleisure-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-find-us-js": () => import("./../../../src/templates/find-us.js" /* webpackChunkName: "component---src-templates-find-us-js" */),
  "component---src-templates-for-advertisers-js": () => import("./../../../src/templates/for-advertisers.js" /* webpackChunkName: "component---src-templates-for-advertisers-js" */),
  "component---src-templates-gifts-js": () => import("./../../../src/templates/gifts.js" /* webpackChunkName: "component---src-templates-gifts-js" */),
  "component---src-templates-golden-mile-tower-js": () => import("./../../../src/templates/golden-mile-tower.js" /* webpackChunkName: "component---src-templates-golden-mile-tower-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-intermission-bar-js": () => import("./../../../src/templates/intermission-bar.js" /* webpackChunkName: "component---src-templates-intermission-bar-js" */),
  "component---src-templates-membership-js": () => import("./../../../src/templates/membership.js" /* webpackChunkName: "component---src-templates-membership-js" */),
  "component---src-templates-no-spoilers-dining-js": () => import("./../../../src/templates/no-spoilers-dining.js" /* webpackChunkName: "component---src-templates-no-spoilers-dining-js" */),
  "component---src-templates-promos-js": () => import("./../../../src/templates/promos.js" /* webpackChunkName: "component---src-templates-promos-js" */),
  "component---src-templates-releasing-schedules-js": () => import("./../../../src/templates/releasing-schedules.js" /* webpackChunkName: "component---src-templates-releasing-schedules-js" */),
  "component---src-templates-rental-rates-js": () => import("./../../../src/templates/rental-rates.js" /* webpackChunkName: "component---src-templates-rental-rates-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-theme-js": () => import("./../../../src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */),
  "component---src-templates-venue-hire-js": () => import("./../../../src/templates/venue-hire.js" /* webpackChunkName: "component---src-templates-venue-hire-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */)
}

